import TableInfo from '@/components/tableinfo';
import { localTableDataFilter } from '@/utils';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import TopHeader from "@/layout/topHeader";
import { Layout, Card, Table, Space, AutoComplete } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import style from "./index.module.less";
import { getSessionList } from "@/api";

export default function SessionsPage() {
  const params = useParams();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(15);
  const [total] = useState(15);
  const [data, setData] = useState([]);
  const [currentUserInfo] = useState(location.state ? location.state.userinfo : {});
  const [currentApp] = useState(location.state ? location.state.app : {});

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [dataFilterValue, setDataFilterValue] = useState(null);

  const columns = [
    {
      title: 'Session name',
      dataIndex: 'session_name',
      key: 'session_name',
      width: 120,
    },
    {
      title: "App name",
      dataIndex: "app_name",
      key: "app_name",
      width: 160,
    },
    {
      title: "App owner name",
      dataIndex: "app_owner_name",
      key: "app_owner_name",
      width: 150,
    },
    {
      title: "App key",
      dataIndex: "app_key",
      key: "app_key",
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'host',
      width: 100,
      ellipsis: true,
      render: (text) => text ? <>{text}</> : <span style={{ color: '#999' }}>NULL</span>
    },
    {
      title: 'Zoom ID',
      dataIndex: 'zoom_id',
      key: 'zoom_id',
      width: 100,
      ellipsis: true,
    },
    {
      title: "Duration(s)",
      dataIndex: "duration",
      key: "duration",
      width: 120,
      render: (_, record) => {
        // const diff = dayjs.utc().valueOf() - dayjs.utc(record.updated_at).valueOf();
        // if (diff > 24 * 60 * 60 * 1000) {
        //   console.log('show duration');
        // }
        return <>{record.duration}</>;
      }
    },
    {
      title: "Create/Update Date",
      dataIndex: "at",
      key: "at",
      width: 160,
      render: (_, record: any) => (
        <div style={{ fontSize: 13 }}>
          <div>{dayjs(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div style={{ color: 'blue' }}>
            {dayjs(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      )
    },
  ];

  const querySessionListRequest = ({ app_id, page }) => {
    const params = {
      app_id,
      page,
      limit: pageSize,
    };
    getSessionList(params)
      .then((res) => {
        if (res['data']['data']) {
          setData(res['data']['data']);
          setAutoCompleteOptions(_.uniqBy(res['data']['data'].map((item: any) => ({
            value: item.app_name,
          })), 'value'));
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    querySessionListRequest({ app_id: params.id, page });
  });

  return (
    <Layout style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
      <TopHeader title={"Sessions"} back={currentUserInfo.id || currentApp.id} />

      <Card className={style.container}>
        <TableInfo user={currentUserInfo} app={currentApp} />
        <Space size={'middle'} className="searchview">
          Search app name:
          <AutoComplete
            allowClear
            style={{ width: 200 }}
            placeholder={'Please enter input'}
            options={autoCompleteOptions}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(value) => setDataFilterValue(value)}
            onClear={() => setDataFilterValue(null)}
          />
        </Space>
        <Table
          loading={loading}
          columns={columns}
          dataSource={localTableDataFilter(data, dataFilterValue, { dataKey: 'app_name' })}
          scroll={{ y: 500 }}
          rowKey={record => record.id}
          pagination={{
            pageSize,
            total,
            position: "bottomRight",
            onChange: (page) => setPage(page),
            showTotal: (total, range) => {
              const label = range[0] === range[1] ? range[0] : `${range[0]}-${range[1]}`;
              return `${label} of ${total} items`;
            },
          }}
        />
      </Card>
    </Layout>
  );
}
