import storageUtil from "@/utils/storageUtil";
import _ from "lodash";
import { pathToRegexp } from "path-to-regexp";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routers, getAccessRouter } from "./routers";

function useRouter() {
  const routerList = [];
  routers.forEach((item, index) => {
    routerList.push(<Route key={index} path={item["path"]} element={item["component"]}></Route>);
  });
  return routerList;
}

const Router = () => {
  const routerList = useRouter();
  const location = useLocation();
  const navigate = useNavigate();
  const [userInfo] = useState(storageUtil.getUserInfo());

  useEffect(() => {
    const menuAccess = getAccessRouter(userInfo);
    const firstMenu = _.first(menuAccess);
    // 未经授权的路由重定向
    if (menuAccess.findIndex((item) => pathToRegexp(item.path).test(location.pathname)) < 0) {
      navigate("/403");
    }
    // 从根路由重定向
    if (
      firstMenu &&
      firstMenu.path &&
      location.pathname === "/" &&
      !pathToRegexp(firstMenu.path).test(location.pathname)
    ) {
      navigate(firstMenu.path);
    }
  }, [location.pathname]);

  return <Routes>{routerList}</Routes>;
};
export default Router;
