import _ from 'lodash';

/**
 * 根据 Key 进行本地搜索
 * @param data
 * @param value
 * @param dataKey
 * @returns {*[]}
 */
export function localTableDataFilter(data, value, { dataKey = 'name' }) {
  return value
    ? _.filter(data, (item: any) => _.includes(item[dataKey].toUpperCase(), value.toUpperCase()))
    : data;
}

/**
 * 用户管理根据 username, email 进行本地搜索
 * @param data
 * @param value
 * @param dataKeys
 * @returns {string[]|*}
 */
export function localUserTableDataFilter(data, value, dataKeys = []) {
  if (value) {
    const values = [];
    data.forEach((item, index) => {
      let isBreak = false;
      dataKeys.forEach(key => {
        if (isBreak === false && item[key] && _.includes(item[key]?.toUpperCase(), value?.toUpperCase())) {
          values.push({ ...item, key: `table_data_${index}` });
          isBreak = true;
        }
      });
    });
    return values;
  }
  return data;
}