import { getSettings, storeSettings } from '@/api';
import TopHeader from '@/layout/topHeader';
import { CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Form, Input, InputNumber, Layout, List, Modal, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styles from './index.module.less';

export default function Settings() {
  const [data, setData] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  //
  const [loadingKey, setLoadingKey] = useState();
  const [editKey, setEditKey] = useState();
  const [editValue, setEditValue] = useState({});
  //
  const [create, setCreate] = useState(false);
  const [createForm] = useForm();
  //
  const [group, setGroup] = useState(false);
  const [groupForm] = useForm();
  //
  const [currentGroup, setCurrentGroup] = useState();

  const fetchSettings = () => {
    getSettings().then((response: any) => {
      if (response.success) {
        setData(response.data);
      }
    });
  };

  const updateSettingFieldValue = (values) => {
    setLoadingKey(values.key);
    const { group, ...object } = values;
    return storeSettings(group, {[object.key]: editValue[object.key]}).then((response: any) => {
      if (response.success) {
        console.log(response);
        setData({
          ...data,
          [group]: response.data,
        });
      }
    }).finally(() => {
      setLoadingKey(null);
      setEditKey(null);
    });
  };

  const createGroup = (values) => {
    const { group, key, value } = values;
    return storeSettings(group, {[key]: value}).then((response) => {
      setData({
        ...data,
        [group]: response.data,
      })
    }).finally(() => {
      setConfirmLoading(false);
    });
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Layout style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
      <TopHeader title={'Settings'}/>
      {_.map(data, (item, group) => {
        return (
          <Card
            key={group}
            className={styles.container}
            title={_.capitalize(group)}
            extra={(
              <Space size={12}>
                <Button
                  type={'primary'}
                  icon={<PlusOutlined/>}
                  onClick={() => {
                    setCurrentGroup(group);
                    setCreate(true);
                  }}
                >
                  New fields
                </Button>
              </Space>
            )}
          >
            <List
              dataSource={_.map(item, (value, key) => ({ key, value, group }))}
              renderItem={r => (
                <List.Item key={r.key} extra={(
                  <>
                    {editKey === r.key
                      ? (
                        <div>
                          {
                            loadingKey === r.key
                              ? <LoadingOutlined style={{ color: '#ccc' }} />
                              : <CheckOutlined onClick={() => updateSettingFieldValue(r)}/>
                          }
                          <Divider type={'vertical'}/>
                          <CloseOutlined
                            onClick={() => loadingKey !== r.key && setEditKey(null)}
                            style={loadingKey === r.key ? { color: '#ccc' } : {}}
                          />
                        </div>
                      )
                      : <EditOutlined onClick={() => setEditKey(r.key)} />
                    }
                  </>
                )}>
                  <Space size={12} style={{ height: 30 }}>
                    <span>{r.key}:</span>
                    {
                      editKey !== r.key
                        ? <span style={{ fontWeight: 'bold' }}>{r.value}</span>
                        : (
                          r.key === 'charge_amount' ? <InputNumber
                            style={{ width: 200 }}
                            placeholder={'Please input your key value'}
                            min={0.0035}
                            step={0.0001}
                            defaultValue={r.value}
                            onChange={(value) => setEditValue({ [r.key]: value })}
                            onPressEnter={(e) => {
                              setEditValue({ [r.key]: e.target.value });
                              updateSettingFieldValue(r);
                            }}
                          /> : <Input
                            defaultValue={r.value}
                            placeholder={'Please input your key value'}
                            onChange={(e) => setEditValue({ [r.key]: e.target.value })}
                            onPressEnter={(e) => {
                              setEditValue({ [r.key]: e.target.value });
                              updateSettingFieldValue(r);
                            }}
                          />
                        )
                    }
                    {/* TODO 注意，这里还需要根据类型使用不同的输入框组件，但目前并没有更多的设置 */}
                  </Space>
                </List.Item>
              )}
            />
          </Card>
        );
      })}
      <div className={styles.container} style={{ marginBottom: 24 }}>
        <Button type={'dashed'} block icon={<PlusOutlined />} onClick={() => setGroup(true)}>
          Add group and field
        </Button>
      </div>
      <Modal
        width={800}
        open={create}
        onCancel={() => setCreate(false)}
        title={'New fields'}
        okText={'Submit'}
        confirmLoading={confirmLoading}
        onOk={() => {
          setConfirmLoading(true);
          createForm.submit();
        }}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 8 }}
          form={createForm}
          onFinish={() => {
            createForm.validateFields().then((values) => {
              createGroup({ group: currentGroup, ...values }).then(() => setCreate(false));
            });
          }}
        >
          <Form.Item label={'Group name'}>
            {currentGroup ?? 'NULL'}
          </Form.Item>
          <Form.Item
            label={'Name'}
            name={'key'}
            required
            rules={[{
              required: true,
              message: 'Please input your key name',
            }]}
          >
            <Input style={{ width: 300 }} placeholder={'Please input your key name'}/>
          </Form.Item>
          <Form.Item
            label={'Value'}
            name={'value'}
            required
            rules={[{
              required: true,
              message: 'Please input your key value',
            }]}
          >
            <Input style={{ width: 300 }} placeholder={'Please input your key value'}/>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={800}
        title={'Add group'}
        open={group}
        confirmLoading={confirmLoading}
        onCancel={() => setGroup(false)}
        okText={'Submit'}
        onOk={() => {
          setConfirmLoading(true);
          groupForm.submit();
        }}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 8 }}
          form={groupForm}
          onFinish={() => {
            groupForm.validateFields().then((values) => {
              createGroup(values).then(() => setGroup(false));
            });
          }}
        >
          <Form.Item
            label={'Group name'}
            name={'group'}
            required
            rules={[{
              required: true,
              message: 'Please input your group name',
            }]}
          >
            <Input style={{ width: 300 }} placeholder={'Please input your key name'}/>
          </Form.Item>
          <Form.Item
            label={'Key name'}
            name={'key'}
            required
            rules={[{
              required: true,
              message: 'Please input your key name',
            }]}
          >
            <Input style={{ width: 300 }} placeholder={'Please input your key name'}/>
          </Form.Item>
          <Form.Item
            label={'Key value'}
            name={'value'}
            required
            rules={[{
              required: true,
              message: 'Please input your key value',
            }]}
          >
            <Input style={{ width: 300 }} placeholder={'Please input your key value'}/>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
}