import { UserOutlined } from '@ant-design/icons';
import { Avatar, Descriptions, Tag } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const TableInfo = ({ user = {}, app = {} }) => {
  const notSet = <span style={{ color: '#999' }}>Not set</span>;
  const title = user.id && (
    <div>
      <Avatar src={user.avatar_url} icon={<UserOutlined />} />
      <span style={{ marginLeft: 12 }}>{user.name}</span>
    </div>
  );

  return <div style={{ marginBottom: 12 }}>
    <Descriptions title={title}>
      {user.id && (<>
        <Descriptions.Item label="User roles">
          {user.roles.map((item, index) => (
            <Tag key={index} color={item === 'client' ? 'blue' : 'green'}>{item}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="User eMail">{user.email}</Descriptions.Item>
        <Descriptions.Item label="User create date">
          {dayjs(user.created_at).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
      </>)}
      {app.id && <>
        <Descriptions.Item label="App name">{app.name}</Descriptions.Item>
        <Descriptions.Item label="App BundleID">
          {app.bundle_id || notSet}
        </Descriptions.Item>
        <Descriptions.Item label="App PackageID">
          {app.package_id || notSet}
        </Descriptions.Item>
        <Descriptions.Item label="App Create date">
          {dayjs(app.created_at).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
      </>}
    </Descriptions>
  </div>;
};

export default TableInfo;