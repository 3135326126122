import { getAccessRouter } from "@/router/routers";
import storageUtil from "@/utils/storageUtil";
import { Menu } from "antd";
import { pathToRegexp } from "path-to-regexp";
import React, { useEffect, useState } from "react";
import "./index.less";
import { useLocation } from "react-router-dom";

const SiderMenu = () => {
  const location = useLocation();
  const [userInfo] = useState(storageUtil.getUserInfo());
  const [current, setCurrent] = useState("index");

  useEffect(() => {
    const router = getAccessRouter(userInfo);
    const menuIndex = router.findIndex((item) => pathToRegexp(item.path).test(location.pathname));
    setCurrent(menuIndex >= 0 ? router[menuIndex].key : null);
  }, [userInfo, location.pathname]);

  return (
    <Menu
      onClick={(e) => setCurrent(e.key)}
      items={getAccessRouter(userInfo, true)}
      selectedKeys={[current]}
      mode={"inline"}
      className={"layout-silder-menu"}
    />
  );
};

export default SiderMenu;
