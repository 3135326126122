import MyIcon from "@/components/icon";
import Results from "@/components/results";
import Login from '@/pages/login';
import Settings from '@/pages/settings';
import UserManage from "@/pages/user-manage";
import AppManage from "@/pages/app-manage";
import BillsPage from "@/pages/bills";
import SessionsPage from "@/pages/sessions";
import { UserInfoModel } from "@/utils/interface";
import { SettingOutlined } from '@ant-design/icons';
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";

export const routers = [
  {
    label: <Link to={"/"}>Index</Link>,
    icon: <MyIcon type={"icon-user-manage"} />,
    key: "index",
    path: "/",
    component: <></>,
  },
  {
    label: <Link to={"/login"}>Login</Link>,
    icon: <MyIcon type={"icon-user-manage"} />,
    key: "login",
    path: "/login",
    hideMenu: true,
    component: <Login />,
  },
  {
    label: <Link to={"/user-manage"}>User manage</Link>,
    icon: <MyIcon type={"icon-user-manage"} />,
    key: "user-manage",
    path: "/user-manage",
    component: <UserManage />,
    access: ["super-admin"],
  },
  {
    label: <Link to={"/app-manage"}>Apps</Link>,
    icon: <MyIcon type={"icon-app-manage"} />,
    key: "app-manage",
    path: "/app-manage",
    component: <AppManage />,
    access: ["super-admin", "client"],
  },
  {
    label: <Link to={"/app-manage"}>Apps</Link>,
    icon: <MyIcon type={"icon-app-manage"} />,
    key: "app-manage",
    hideMenu: true,
    path: "/app-manage/:id",
    component: <AppManage />,
    access: ["super-admin", "client"],
  },
  {
    label: <Link to={"/bills"}>Bills</Link>,
    icon: <MyIcon type={"icon-bill"} />,
    key: "bills",
    path: "/bills",
    component: <BillsPage />,
    access: ["super-admin", "client"],
  },
  {
    label: <Link to={"/bills"}>Bills</Link>,
    icon: <MyIcon type={"icon-bill"} />,
    key: "bills",
    path: "/bills/:id",
    hideMenu: true,
    component: <BillsPage />,
    access: ["super-admin", "client"],
  },
  // {
  //   label: <Link to={"/role-list"}>Role lists</Link>,
  //   icon: <MyIcon type={"icon-recharge"} />,
  //   key: "role-list",
  //   path: "/role-list",
  //   component: <RoleList />,
  //   access: ["super-admin"],
  // },
  {
    label: <Link to={"/sessions"}>Sessions</Link>,
    icon: <MyIcon type={"icon-lishihuiyi"} />,
    key: "sessions",
    path: "/sessions",
    component: <SessionsPage />,
    access: ["super-admin", "client"],
  },
  {
    label: <Link to={"/sessions"}>Sessions</Link>,
    icon: <MyIcon type={"icon-lishihuiyi"} />,
    key: "sessions",
    hideMenu: true,
    path: "/sessions/:id",
    component: <SessionsPage />,
    access: ["super-admin", "client"],
  },
  {
    label: <Link to={"/settings"}>Settings</Link>,
    icon: <SettingOutlined />,
    key: "settings",
    hideMenu: true,
    path: "/settings",
    component: <Settings />,
    access: ["super-admin"],
  },
  {
    path: "/403",
    component: <Results />,
  },
];

export function getAccessRouter(userInfo: UserInfoModel, excludeHideMenu = false) {
  return routers.filter(
    (item) => _.intersection(item.access, userInfo.roles).length > 0 && item.hideMenu !== excludeHideMenu
  );
}
