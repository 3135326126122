import { WarningOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

export default () => {
  const navigate = useNavigate();

  return <Result
    icon={<WarningOutlined />}
    status={'error'}
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={<Button type="primary" onClick={() => navigate('/')}>Back Home</Button>}
  />;
}