import './App.css';
import AppRouter from "@/router/appRouter"

function App() {
  return (
    <AppRouter/>
  );
}

export default App;
