import ajax from "@/api/ajax.js";

const request = ajax;

/**
 * 登录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const login = (data) => request.post("/auth/login", data);

/**
 * 获取当前用户信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserInfo = () => request.get("/auth/info");

/**
 * 获取所有权限列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPermissionList = (data) => request.get("/permissions", data);

/**
 * 获取所有角色列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRoleList = (data) => request.get("/roles", data);

/**
 * 添加用户
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addUsers = (data) => request.post("/users", data);

/**
 * 添加角色
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addRoles = (data) => request.post("/roles", data);

/**
 * 删除角色
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteRoles = (id) => request.delete("/roles/" + id);

/**
 * 获取角色详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRoleDetail = (id) => request.get("/roles/" + id);

/**
 * 修改角色
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateRole = (id, data) => request.post("/roles/" + id, data);

/**
 * 获取用户列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserList = (data) => request.get("/users", data);

/**
 * 修改用户信息
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateUser = (id, data) => request.put("/users/" + id, data);

/**
 * 赋予用户角色
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const assignUserRoles = (data) => request.post("/roles/assign/user", data);

/**
 * 获取app列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAppList = (data) => request.get("/app", data);

/**
 * 获取app详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAppDetail = (id) => request.get("/app/" + id);

/**
 * 添加app
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addApp = (data) => request.post("/app/app", data);

/**
 * 更新app
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateApp = (id, data) => request.post("/app/app/" + id, data);

/**
 * 获取session列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSessionList = (data) => request.get('/sessions', data);

/**
 * 获取session详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSessionDetail = (id) => request.get("/sessions/" + id);

/**
 * 获取Bill列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getBillList = (data) => request.get("/bills", data);

/**
 * 获取Bill详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getBillDetail = (id) => request.get("/bills/" + id);

/**
 * 获取邮箱验证码
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVertifyCode = (data) => request.post("/auth/validate-reset-password", data);

/**
 * 修改密码
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const resetPassword = (data) => request.post("/auth/reset-password", data);

/**
 * 获取配置
 * @param data
 * @returns {*}
 */
export const getSettings = (data) => request.get('/settings', data);

/**
 * 添加配置
 * @param name
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeSettings = (name, data) => request.post(`/settings/${name}`, data);