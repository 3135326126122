import { StorageUtil, UserInfoModel } from "@/utils/interface";

const TOKEN = "TOKEN";
const USERINFO = "USERINFO";

const storageUtil: StorageUtil = {
  saveToken(token) {
    localStorage.setItem(TOKEN, token);
  },

  getToken() {
    return localStorage.getItem(TOKEN);
  },

  clearToken() {
    localStorage.removeItem(TOKEN);
  },

  saveUserInfo(userInfo) {
    localStorage.setItem(USERINFO, JSON.stringify(userInfo));
  },

  getUserInfo(): UserInfoModel {
    return JSON.parse(localStorage.getItem(USERINFO));
  },

  clearUserInfo() {
    localStorage.removeItem(USERINFO);
  },

  setKeyValue(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  getKeyValue(key) {
    return JSON.parse(localStorage.getItem(key));
  },

  removeKeyValue(key) {
    localStorage.removeItem(key);
  },
};

export default storageUtil;
