import { localUserTableDataFilter } from '@/utils';
import eventBus from '@/utils/eventBus';
import storageUtil from '@/utils/storageUtil';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useState, useEffect } from "react";
import TopHeader from "@/layout/topHeader";
import {
  Button,
  Layout,
  Space,
  Table,
  Card,
  Input,
  Tag,
  Modal,
  Form,
  Select,
  message, Avatar, AutoComplete,
} from 'antd';
import {
  getUserList,
  getRoleList,
  addUsers,
  updateUser,
  assignUserRoles,
} from "@/api";
import { Link } from "react-router-dom";
import "./index.less";

const valueView = (text) => {
  return <span className="value-view">{text}</span>;
};

const tagView = (roles) => <div>
  {roles.map((item, index) => (
    <Tag key={`role_tag_${index}`} color={item === "client" ? "blue" : "green"}>
      {item}
    </Tag>
  ))}
</div>;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

export default function UserManage() {
  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [userListData, setUserListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(15);
  const [total, setTotal] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [roleListData, setRoleListData] = useState([]);
  const { Option } = Select;
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentUserName, setCurrentUserName] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [currentUserRoles, setCurrentUserRoles] = useState([]);
  const user = storageUtil.getUserInfo();

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [dataFilterValue, setDataFilterValue] = useState(null);

  const defaultPasswordValue = 'fondcircle';

  useEffect(() => {
    queryUserListData(page);
  }, [page]);

  const columns = [
    {
      title: "User name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (_, record) => (
        <div style={{ display: 'flex' }}>
          <Avatar size={24} src={record.avatar_url} icon={<UserOutlined />} />
          <span style={{ marginLeft: 6 }}>{record.name}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      render: (text) => valueView(text),
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      width: 180,
      render: (roles) => tagView(roles),
    },
    {
      title: "Create/Update Date",
      dataIndex: "at",
      key: "at",
      width: 160,
      render: (_, record: any) => (
        <div style={{ fontSize: 13 }}>
          <div>{dayjs(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div style={{ color: 'blue' }}>
            {dayjs(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      )
    },
    {
      title: "Action",
      dataIndex: 'action',
      key: "action",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              editUserClick(record);
            }}
          >
            Edit
          </a>
          <Link to={{ pathname: `/app-manage/${record.id}` }} state={{ userinfo: record }}>
            View App
          </Link>
        </Space>
      ),
    },
  ];

  const queryUserListData = (page) => {
    const params = {
      page,
      limit: pageSize,
    };
    setLoading(true);
    getUserList(params)
      .then((res) => {
        if (res["data"]["data"] != null) {
          setUserListData(res["data"]["data"]);
          setTotal(res["data"]["meta"]["pagination"]["total"]);
          //
          const options = [];
          res['data']['data'].forEach((item, index) => {
            options.push({ key: `option_${index}_name`, value: item.name });
            options.push({ key: `option_${index}_email`, value: item.email });
          });
          setAutoCompleteOptions(options);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///获取角色列表
  const queryRoleList = () => {
    getRoleList({ type: 'all' })
      .then((res) => {
        if (res["data"] != null) {
          setRoleListData(res["data"]["data"]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //创建用户点击
  const createNewUserClick = () => {
    setIsEdit(false);
    queryRoleList();
    setCurrentUserId(null);
    setCurrentUserName("");
    setCurrentUserEmail("");
    setCurrentUserRoles(['client']);
    setOpen(true);
  };

  //编辑用户点击
  const editUserClick = (user) => {
    setIsEdit(true);
    queryRoleList();
    setCurrentUserId(user.id);
    setCurrentUserName(user.name);
    setCurrentUserEmail(user.email);
    setCurrentUserRoles(user.roles);
    setOpen(true);
  };

  // const onTablePageChanged = (value) => {
  //   queryUserListData(value["current"]);
  //   setPage(value["current"]);
  // };

  const onFormFinish = (value) => {
    if (isEdit) {
      // assignUserRolesRequest(value);
      updateUserRequest(value);
    } else {
      createNewUserRequest(value);
    }
  };

  const onFormValuesChanged = async (value) => {
    const superAdmin = _.includes(currentUserRoles, 'super-admin');
    const find = _.includes(value.roles, 'super-admin');
    if (superAdmin && find === false) {
      form.setFieldValue('roles', ['super-admin', ...value.roles]);
      await message.destroy();
      await message.warning('Unable to remove super administrator permission!');
    }
  };

  const createNewUserRequest = (params) => {
    addUsers(params)
      .then(async (res) => {
        if (res["success"]) {
          queryUserListData(page);
          setOpen(false);
          await message.success("User create success");
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUserRequest = (params) => {
    const { roles, ...objects } = params;
    updateUser(currentUserId, objects)
      .then(async (res) => {
        if (res["success"]) {
          assignUserRolesRequest(roles);
          if (currentUserId === user.id) {
            storageUtil.saveUserInfo(res["data"]);
            eventBus.emit("userInfoChanged");
          }
          setOpen(false);
          await message.success("User update success");
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const assignUserRolesRequest = (params) => {
    const data = {
      user_id: currentUserId,
      roles: params,
    };
    if (
      currentUserRoles.length === data["roles"].length &&
      currentUserRoles.sort().toString() === data["roles"].sort().toString()
    ) {
      queryUserListData(page);
    } else {
      assignUserRoles(data)
      .then((res) => {
        if (res["success"]) {
          if (currentUserId === user.id) {
            showNeedReLoginTips();
          } else {
            queryUserListData(page);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  //修改用户
  const showNeedReLoginTips = () => {
    Modal.warning({
      title: "Tips",
      content: (
        <div>
          <p>Your account permissions have been modified, please log in again！</p>
        </div>
      ),
      onOk: () => {
        eventBus.emit("logout-success");
      },
    });
  };

  const rightActions = [
    <Button key={'right_actions'} type="primary" onClick={createNewUserClick}>
      Create user
    </Button>,
  ];

  const userModalView = (isEdit) => {
    return (
      <Modal
        destroyOnClose={true}
        title={isEdit ? "Edit user info" : "Create new user"}
        open={open}
        afterClose={() => {
          form.resetFields();
        }}
        onOk={() => {
          form.validateFields()
            .then(() => {
              setConfirmLoading(true);
              form.submit();
            })
            .catch(() => {
              setConfirmLoading(false);
            });
        }}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
        width={768}
        okText={'Submit'}
      >
        <Form
          {...layout}
          onFinish={onFormFinish}
          form={form}
          onValuesChange={onFormValuesChanged}
        >
          <Form.Item
            initialValue={currentUserName}
            name={"name"}
            label="User name"
            rules={[
              {
                required: true,
                message: "Please input a user name",
              },
            ]}
          >
            <Input placeholder={'Please input your user name'} />
          </Form.Item>
          <Form.Item
            initialValue={currentUserEmail}
            name={"email"}
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input user email",
              },
              {
                type: 'email',
                message: 'Please enter the correct email address',
              },
            ]}
          >
            <Input placeholder={'Please input user email'} />
          </Form.Item>
          {isEdit ? null : (
            <Form.Item
              name={"password"}
              label="Password"
              initialValue={defaultPasswordValue}
              rules={[
                {
                  required: true,
                  message: "Please input password",
                },
                {
                  min: 6,
                  message: 'Password must be at least 6 digits',
                }
              ]}
            >
              <Input.Password placeholder={'Please input password'} />
            </Form.Item>
          )}
          {isEdit ? null : (
            <Form.Item
              name={"confirm_password"}
              label="Confirm password"
              initialValue={defaultPasswordValue}
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                {
                  min: 6,
                  message: 'Password must be at least 6 digits',
                },
                (validator) => ({
                  validator(_, value) {
                    if (!value || validator.getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  }
                }),
              ]}
            >
              <Input.Password placeholder={'Please confirm your password'} />
            </Form.Item>
          )}
          <Form.Item
            name="roles"
            label="Roles"
            initialValue={currentUserRoles}
            rules={[
              {
                required: true,
                message: "Please select roles!",
                type: "array",
              },
            ]}
          >
            <Select mode="multiple" placeholder="Please select roles">
              {roleListData.map((item, index) => (
                <Option key={`select_role_${index}`} value={item["name"]}>{item["name"]}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  return (
    <Layout style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
      <TopHeader title={"User Manage"} rightActions={rightActions} back={false} />
      <div className="container">
        <Card style={{ height: '100%' }}>
          <Space size={'middle'} className="searchview">
            Search:
            <AutoComplete
              allowClear
              style={{ width: 200 }}
              placeholder={'user name, email'}
              options={autoCompleteOptions}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) => setDataFilterValue(value)}
              onClear={() => setDataFilterValue(null)}
            />
          </Space>
          <Table
            loading={loading}
            dataSource={localUserTableDataFilter(userListData, dataFilterValue, ['name', 'email'])}
            columns={columns}
            rowKey={record => record.id}
            scroll={{ y: 500 }}
            pagination={{
              pageSize,
              total,
              position: "bottomRight",
              onChange: (page) => setPage(page),
              showTotal: (total, range) => {
                const label = range[0] === range[1] ? range[0] : `${range[0]}-${range[1]}`;
                return `${label} of ${total} items`;
              },
            }}
          />
        </Card>
      </div>
      {userModalView(isEdit)}
    </Layout>
  );
}
