import MainLayout from '@/layout';
import eventBus from '@/utils/eventBus';
import storageUtil from '@/utils/storageUtil';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import Login from '../pages/login';

const Router = process.env.REACT_APP_ROUTER_ISHASH === '1' ? HashRouter : BrowserRouter;
const RouterBasename = process.env.REACT_APP_ROUTERBASE || '/';

function AppRouter() {
  const [token, setToken] = useState(storageUtil.getToken());

  const loginSuccess = () => {
    setToken(storageUtil.getToken());
  };

  const logoutSuccess = () => {
    storageUtil.clearToken();
    storageUtil.clearUserInfo();
    setToken(null);
  };
  useEffect(() => {
    eventBus.addListener('login-success', loginSuccess);
    eventBus.addListener('logout-success', logoutSuccess);
    return () => {
      eventBus.removeListener('login-success', loginSuccess);
      eventBus.removeListener('logout-success', logoutSuccess);
    };
  }, []);

  return (
    <Router basename={RouterBasename}>
      {token == null ? <Login /> : <MainLayout />}
    </Router>
  );
}

export default AppRouter;