import logo from "@/assets/images/logo.svg";
import MyIcon from "@/components/icon";
import eventBus from "@/utils/eventBus";
import storageUtil from "@/utils/storageUtil";
import { Dropdown, Layout, Menu, Form, Modal, message, Input, Space, Button } from 'antd';
import _ from 'lodash';
import React, { useState, useEffect } from "react";
import { EyeInvisibleOutlined, EyeTwoTone, SettingOutlined } from '@ant-design/icons';
import { getVertifyCode, resetPassword } from "@/api";
import "./index.less";
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

const RightMenu = ({ userinfo, setting, logout, resetPassword }) => {
  const rightMenu = [
    {
      label: <div onClick={resetPassword}>Reset password</div>,
      icon: <MyIcon style={{ fontSize: "16px" }} type="icon-zhongzhimima" />,
      key: "reset_password",
    },
    {
      label: <div onClick={logout}>Logout</div>,
      icon: <MyIcon style={{ fontSize: "16px" }} type="icon-tuichudenglu" />,
      key: "logout",
    },
  ];
  if (_.includes(userinfo.roles, 'super-admin')) {
    rightMenu.unshift({
      label: <div onClick={setting}>Settings</div>,
      icon: <SettingOutlined />,
      key: 'settings',
    });
  }
  return <Menu className="right-down" items={rightMenu} />;
};

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

const getPopupContainer = (HTMLElement) => HTMLElement;

const LayoutHeader = ({ children }) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(storageUtil.getUserInfo());
  const [open, setOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(userInfo.email);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [codeTips, setCodeTips] = useState('Get verification code');
  const [waitStatus, setWaitStatus] = useState(false);
  const [getCode, setGetCode] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    eventBus.addListener("userInfoChanged", changeUserName);
    return () => {
      eventBus.removeListener("userInfoChanged", changeUserName);
    };
  }, []);

  const onEmailInputChanged = (e) => {
    setCurrentEmail(e.target.value);
  };

  const QueryVerifyCodeRequest = () => {
    if (waitStatus) {
      return;
    }
    let waitTime = 59;
    setCodeTips(`${waitTime}s`);
    setWaitStatus(true);
    let interval = setInterval(() => {
      waitTime--;
      setCodeTips(`${waitTime}s`);
      if (waitTime <= 0) {
        setWaitStatus(false);
        setCodeTips('Get verification code');
        clearInterval(interval);
      }
    }, 1000);
    const params = { identifier: currentEmail };
    getVertifyCode(params)
      .then(async (res) => {
        if (res["success"]) {
          await message.success("Verification code has been sent to your email");
        }
      })
      .finally(() => {
        setGetCode(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showChangePassword = () => {
    setOpen(true);
  };

  const onFormFinish = (value) => {
    resetPassword(value)
      .then(async (res) => {
        if (!res["success"]) {
          await message.error(res["message"]);
        } else {
          setOpen(false);
          showNeedReLoginTips();
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showNeedReLoginTips = () => {
    Modal.warning({
      title: "Tips",
      content: (
        <div>
          <p>Your account password has been changed, please log in again with the new password！</p>
        </div>
      ),
      onOk: () => {
        eventBus.emit("logout-success");
      },
    });
  };
  const changePasswordModalView = () => {
    return (
      <Modal
        destroyOnClose={true}
        title={"Change password"}
        open={open}
        afterClose={() => {
          form.resetFields();
        }}
        okText={'Submit'}
        confirmLoading={confirmLoading}
        onOk={async () => {
          if (getCode) {
            form.submit();
          } else {
            await message.destroy();
            await message.warning('Please get verification code');
          }
        }}
        onCancel={() => setOpen(false)}
        width={800}
      >
        <Form
          {...layout}
          onFinish={onFormFinish}
          form={form}
          // onValuesChange={onFormValuesChanged}
        >
          <Form.Item
            name={"identifier"}
            label="Email"
            initialValue={currentEmail}
            rules={[
              {
                required: true,
                message: "Please input your email",
              },
            ]}
          >
            <Input
              // onChange={onEmailInputChanged}
              disabled={currentEmail}
              style={{ width: 260 }}
              placeholder="Please enter your account email"
            />
          </Form.Item>
          <Form.Item
            name={'code'}
            label={'Verification code'}
            rules={[
              {
                required: true,
                message: "Please input your Verification code",
              },
            ]}
          >
            <Input.Group compact>
              <Input
                style={{ width: 150 }}
                placeholder={'Please input code'}
                onChange={(e) => form.setFieldValue('code', e.target.value)}
                allowClear
              />
              <Button onClick={QueryVerifyCodeRequest} disabled={waitStatus}>
                {codeTips}
              </Button>
            </Input.Group>
          </Form.Item>
          <Form.Item
            name={"password"}
            label="New password"
            rules={[
              {
                required: true,
                message: "Please input your new password",
              },
            ]}
          >
            <Input.Password
              style={{ width: 260 }}
              placeholder="Please enter your new password"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item
            name={"password_confirmation"}
            label="Password confirm"
            rules={[
              {
                required: true,
                message: "Please input your new password",
                validator: (rlue, value, callback) => {
                  let passwordValue = form.getFieldValue("password");
                  if (value.length === 0) {
                    callback("Please enter the password again");
                    return;
                  }
                  if (passwordValue !== value) {
                    callback("The two entered passwords do not match");
                    return;
                  }
                  callback();
                },
              },
            ]}
          >
            <Input.Password
              style={{ width: 260 }}
              placeholder="Please enter your new password"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  const changeUserName = () => {
    setUserInfo(storageUtil.getUserInfo());
  };
  return (
    <Header className="header">
      <div className="logo">
        <img style={{ height: "64px" }} src={logo} alt="logo"></img>
        <span>FondCircleSDK Admin</span>
      </div>
      {children}
      <div className="right">
        <Dropdown
          placement="bottom"
          getPopupContainer={getPopupContainer}
          overlay={
            <RightMenu
              userinfo={userInfo}
              setting={() => navigate('/settings')}
              resetPassword={showChangePassword}
              logout={() => {
                eventBus.emit("logout-success");
                // navigate('/login');
              }}
            />
          }
        >
          <div className="user-name">{"User: " + userInfo["name"]}</div>
        </Dropdown>
        {changePasswordModalView()}
      </div>
    </Header>
  );
};
export default LayoutHeader;
