import TableInfo from '@/components/tableinfo';
import TopHeader from "@/layout/topHeader";
import { localTableDataFilter } from '@/utils';
import { CloudOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Layout,
  Modal,
  Space,
  Table,
  Form,
  Input,
  Checkbox,
  message,
  Typography, AutoComplete,
} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import { addApp, getAppList, updateApp } from '@/api';
import styles from "./index.module.less";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

export default function AppManage() {
  const params = useParams();
  const location = useLocation();

  const notSet = <span style={{ color: '#aaa' }}>Not set</span>

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text, record) => <>
        {record.is_cloud_messaging_enabled && <CloudOutlined style={{ color: 'rgb(82, 196, 26)' }} />} {text}
      </>,
    },
    {
      title: "BundleID/PackageID",
      dataIndex: "app_id",
      key: "app_id",
      width: 160,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record: any) => (
        <div>
          <div>
            <Typography.Text ellipsis>{record.bundle_id || notSet}</Typography.Text>
          </div>
          <div>
            <Typography.Text ellipsis>{record.package_id || notSet}</Typography.Text>
          </div>
        </div>
      ),
    },
    {
      title: "AppKey",
      dataIndex: "app_key",
      key: "app_key",
      width: 160,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Typography.Text copyable ellipsis={{ tooltip: address }}>{address}</Typography.Text>
      ),
    },
    {
      title: "AppSecret",
      dataIndex: "app_secret",
      key: "app_secret",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Typography.Text copyable ellipsis={{ tooltip: { title: address, placement: 'topLeft' } }}>
          {address}
        </Typography.Text>
      ),
    },
    {
      title: "Create/Update Date",
      dataIndex: "at",
      key: "at",
      width: 160,
      render: (_, record: any) => (
        <div style={{ fontSize: 13 }}>
          <div>{dayjs(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div style={{ color: 'blue' }}>
            {dayjs(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      )
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 180,
      render: (_, record) => (
        <Space>
          <a
            onClick={() => {
              editAppClick(record);
            }}
          >
            Edit
          </a>
          <Link
            to={{ pathname: `/bills/${record.id }` }}
            state={{ userinfo: currentUserInfo, app: record }}
          >
            Bills
          </Link>
          <Link
            to={{ pathname: `/sessions/${record.id}` }}
            state={{ userinfo: currentUserInfo, app: record }}
          >
            Sessions
          </Link>
        </Space>
      ),
    },
  ];
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [total, setTotal] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const [currentUserInfo] = useState(location.state ? location.state.userinfo : {});
  const [currentAppName, setCurrentAppName] = useState("");
  const [currentPackageId, setCurrentPackageId] = useState("");
  const [currentBundleId, setCurrentBundleId] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [enableCloudRecord, setEnableCloudRecord] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [dataFilterValue, setDataFilterValue] = useState(null);

  useEffect(() => {
    queryAppListData({ page, user_id: params.id });
  }, [page, params.id]);

  ///获取app列表
  const queryAppListData = ({ user_id, page }) => {
    const params = {
      user_id,
      page,
      limit: pageSize,
    };
    getAppList(params)
      .then((res) => {
        if (res["data"]["data"] != null) {
          setData(res["data"]["data"]);
          setTotal(res["data"]["meta"]["pagination"]["total"]);
          setAutoCompleteOptions(_.uniqBy(res['data']['data'].map((item: any) => ({
            value: item.name,
          })), 'value'));
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///新建app点击事件
  const createAppClick = () => {
    // setIsIOS(true);
    setCurrentId(null);
    // setCurrentAppId("");
    setCurrentBundleId("");
    setCurrentPackageId("");
    setCurrentAppName("");
    setEnableCloudRecord(false);
    setIsEdit(false);
    setOpen(true);
  };

  ///修改app点击事件
  const editAppClick = (value) => {
    setCurrentId(value["id"]);
    // setCurrentAppId(value["app_id"]);
    setCurrentPackageId(value["package_id"]);
    setCurrentBundleId(value["bundle_id"]);
    setCurrentAppName(value["name"]);
    setEnableCloudRecord(value["is_cloud_messaging_enabled"]);
    setIsEdit(true);
    setOpen(true);
  };

  ///创建app请求
  const createNewAppRequest = (params) => {
    addApp(params)
      .then(async (res) => {
        if (res["success"]) {
          setLoading(true);
          setOpen(false);
          queryAppListData({ page, user_id: params.id });
          await message.success("App create success!");
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///修改app请求
  const updateAppRequest = (params) => {
    updateApp(currentId, params)
      .then(async (res) => {
        if (res["success"]) {
          setLoading(true);
          setOpen(false);
          queryAppListData({ page, user_id: params.id });
          await message.success("App update success!");
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFormFinish = (value) => {
    if (value["package_id"] === "" && value["bundle_id"] === "") {
      Modal.warning({
        title: "Tips",
        content: (
          <div>
            <p>At least one of the package id and bundle id is not empty !</p>
          </div>
        ),
      });
      setConfirmLoading(false);
    } else {
      value["is_cloud_messaging_enabled"] = value["is_cloud_messaging_enabled"] ? 1 : 0;
      if (isEdit) {
        updateAppRequest(value);
      } else {
        createNewAppRequest(value);
      }
    }
  };

  const onFormValuesChanged = (value) => {
    // console.log(value);
  };

  // const onTablePageChanged = (value) => {
  //   queryAppListData({ page: value['current'], user_id: params.id });
  //   setPage(value["current"]);
  // };

  const onModalOkClick = () => {
    setConfirmLoading(true);
    form.validateFields()
      .then(value => {
        form.submit();
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  };

  const modalView = (isEdit) => {
    return (
      <Modal
        title={isEdit ? "Edit App" : "Create App"}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        afterClose={() => form.resetFields()}
        open={open}
        onOk={onModalOkClick}
        onCancel={() => setOpen(!open)}
        okText={"Submit"}
        width={800}
      >
        <Form {...layout} onFinish={onFormFinish} form={form} onValuesChange={onFormValuesChanged}>
          {/* {isEdit ? null : (
            <Form.Item name={"platform"} label="Platform">
              <Radio.Group defaultValue={"ios"}>
                <Radio value="ios"> iOS </Radio>
                <Radio value="android"> Android </Radio>
              </Radio.Group>
            </Form.Item>
          )} */}
          <Form.Item
            initialValue={currentAppName}
            name={"name"}
            label="App name"
            rules={[
              {
                required: true,
                message: "Please input a App name",
              },
            ]}
          >
            <Input placeholder="Please input your app name" />
          </Form.Item>

          <Form.Item initialValue={currentBundleId} name={"bundle_id"} label="Bundle id">
            <Input placeholder="iOS package name" />
          </Form.Item>

          <Form.Item initialValue={currentPackageId} name={"package_id"} label="Package id">
            <Input placeholder="Android package name" />
          </Form.Item>

          <Form.Item
            initialValue={enableCloudRecord}
            name="is_cloud_messaging_enabled"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Enable cloud recording</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  const rightActions = [
    <Button key="1" type="primary" onClick={createAppClick}>
      Create App
    </Button>,
  ];

  return (
    <Layout style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
      <TopHeader title={"App Manage"} rightActions={rightActions} back={!!currentUserInfo.id} />
      <Card className={styles.container}>
        <TableInfo user={currentUserInfo} />
        <Space size={'middle'} className="searchview">
          Search app name:
          <AutoComplete
            allowClear
            style={{ width: 200 }}
            placeholder={'Please enter input'}
            options={autoCompleteOptions}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(value) => setDataFilterValue(value)}
            onClear={() => setDataFilterValue(null)}
          />
        </Space>
        <Table
          loading={loading}
          columns={columns}
          rowKey={record => record.id}
          dataSource={localTableDataFilter(data, dataFilterValue, { dataKey: 'name' })}
          scroll={{ y: 500 }}
          pagination={{
            pageSize,
            total,
            position: "bottomRight",
            onChange: (page) => setPage(page),
            showTotal: (total, range) => {
              const label = range[0] === range[1] ? range[0] : `${range[0]}-${range[1]}`;
              return `${label} of ${total} items`;
            },
          }}
        />
      </Card>
      {modalView(isEdit)}
    </Layout>
  );
}
