import { getAccessRouter } from "@/router/routers";
import _ from "lodash";
import React, { useState } from "react";
import { Button, Checkbox, Form, Input, message, Modal, Space } from "antd";
import MyIcon from "@/components/icon";
import storageUtil from "@/utils/storageUtil";
import eventBus from "@/utils/eventBus";
import { getUserInfo, login, getVertifyCode, resetPassword } from "@/api";
import "./index.less";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const initialValues = {
  remember: true,
  // ...getLocalUser(),
};

const IPT_RULE_USERNAME = [
  {
    required: true,
    message: "Please enter account",
  },
];

const IPT_RULE_PASSWORD = [
  {
    required: true,
    message: "Please enter password",
  },
];
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};
function Login() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [loginForm] = Form.useForm();
  const [form] = Form.useForm();
  const [codeTips, setCodeTips] = useState('Get verification code');
  const [waitStatus, setWaitStatus] = useState(false);
  const [getCode, setGetCode] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onLoginClick = (values) => {
    login(values)
      .then(async (res) => {
        if (res["success"]) {
          message.success("Login Success");
          storageUtil.saveToken(res["data"]["token"]);
          getUserInfo().then(({ data }) => {
            storageUtil.saveUserInfo(data);
            eventBus.emit("login-success");
            const menuAccess = getAccessRouter(data);
            const firstMenu = _.first(menuAccess);
            navigate(firstMenu.path);
          });
        } else {
          await message.error(res["message"]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onEmailInputChanged = (e) => {
    setCurrentEmail(e.target.value);
  };

  const QueryVerifyCodeRequest = () => {
    if (waitStatus) {
      return;
    }
    let waitTime = 59;
    setCodeTips(`${waitTime}s`);
    setWaitStatus(true);
    let interval = setInterval(() => {
      waitTime--;
      setCodeTips(`${waitTime}s`);
      if (waitTime <= 0) {
        setWaitStatus(false);
        setCodeTips('Get verification code');
        clearInterval(interval);
      }
    }, 1000);
    const params = { identifier: currentEmail };
    getVertifyCode(params)
      .then(async (res) => {
        if (res["success"]) {
          await message.success("Verification code has been sent to your email");
        }
      })
      .finally(() => {
        setGetCode(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showChangePassword = () => {
    setOpen(true);
  };

  const onFormFinish = (value) => {
    resetPassword(value)
      .then(async (res) => {
        if (!res["success"]) {
          await message.error(res["message"]);
        } else {
          setOpen(false);
          await message.success("Password reset successfully, please log in with new password！");
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changePasswordModalView = () => {
    return (
      <Modal
        destroyOnClose={true}
        title={"Change password"}
        open={open}
        afterClose={() => form.resetFields()}
        okText={'Submit'}
        confirmLoading={confirmLoading}
        onOk={async () => {
          if (getCode) {
            form.submit();
          } else {
            await message.destroy();
            await message.warning('Please get verification code');
          }
        }}
        onCancel={() => setOpen(false)}
        width={800}
      >
        <Form
          {...layout}
          onFinish={onFormFinish}
          form={form}
          // onValuesChange={onFormValuesChanged}
        >
          <Form.Item
            name={"identifier"}
            label="Email"
            initialValue={loginForm.getFieldValue('email')}
            rules={[
              {
                required: true,
                message: "Please input your email",
              },
            ]}
          >
            <Input
              onChange={onEmailInputChanged}
              style={{ width: 260 }}
              placeholder="Please enter your account email"
            />
          </Form.Item>
          <Form.Item
            name={'code'}
            label={'Verification code'}
            rules={[
              {
                required: true,
                message: "Please input your Verification code",
              },
            ]}
          >
            <Input.Group compact>
              <Input
                style={{ width: 150 }}
                placeholder={'Please input code'}
                onChange={(e) => form.setFieldValue('code', e.target.value)}
                allowClear
              />
              <Button onClick={QueryVerifyCodeRequest} disabled={waitStatus}>
                {codeTips}
              </Button>
            </Input.Group>
          </Form.Item>
          <Form.Item
            name={"password"}
            label="New password"
            rules={[
              {
                required: true,
                message: "Please input your new password",
              },
            ]}
          >
            <Input.Password
              style={{ width: 260 }}
              placeholder="Please enter your new password"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item
            name={"password_confirmation"}
            label="Password confirm"
            rules={[
              {
                required: true,
                message: "Please input your new password",
                validator: (rlue, value, callback) => {
                  let passwordValue = form.getFieldValue("password");
                  if (value.length === 0) {
                    callback("Please enter the password again");
                    return;
                  }
                  if (passwordValue !== value) {
                    callback("The two entered passwords do not match");
                    return;
                  }
                  callback();
                },
              },
            ]}
          >
            <Input.Password
              style={{ width: 260 }}
              placeholder="Please enter your new password"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  return (
    <div className="login-container">
      <div className="wrapper">
        <div className="title">FondCircleSDK Admin</div>
        <div className="welcome">Welcome, please login first</div>
        <Form className="login-form" form={loginForm} initialValues={initialValues} onFinish={onLoginClick}>
          <Form.Item name="email" rules={IPT_RULE_USERNAME}>
            <Input
              prefix={<MyIcon type="icon-zhanghao" />}
              placeholder="Please enter account"
              onChange={onEmailInputChanged}
            />
          </Form.Item>
          <Form.Item name="password" rules={IPT_RULE_PASSWORD}>
            <Input prefix={<MyIcon type="icon-mima" />} type="password" placeholder="Please enter password" />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember password</Checkbox>
              <a onClick={showChangePassword}>Forgot password? </a>
            </Form.Item>
          </Form.Item>
          <Form.Item className="btns">
            <Button type="primary" htmlType="submit" className="login-btn" loading={false}>
              Login
            </Button>

            {/* <Button htmlType="reset">Register</Button> */}
          </Form.Item>
        </Form>
        {changePasswordModalView()}
      </div>
    </div>
  );
}

export default Login;
