import RouteMap from '@/router';
import { Layout } from 'antd';
import MHeader from './header';
import './index.less';
import SiderMenu from './siderMenu';

const { Content, Sider } = Layout;

const MainLayout = () => {
  return (
    <Layout>
      <MHeader/>
      <Layout>
        <Sider theme="light">
          <SiderMenu/>
        </Sider>
        <Content className="layout-content-wrap">
          <RouteMap />
        </Content>
        {/* <Footer>footer</Footer> */}
      </Layout>
    </Layout>
  );
};
export default MainLayout;