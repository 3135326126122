import { getBillList } from '@/api';
import TableInfo from '@/components/tableinfo';
import { localTableDataFilter } from '@/utils';
import storageUtil from '@/utils/storageUtil';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import TopHeader from '@/layout/topHeader';
import { Layout, Card, Table, Space, Button, Badge, AutoComplete } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import style from './index.module.less';

export default function BillsPage() {
  const params = useParams();
  const location = useLocation();

  console.log(params);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(15);
  const [total, setTotal] = useState(0);
  const [currentUserInfo] = useState(location.state ? location.state.userinfo : {});
  const [currentApp] = useState(location.state ? location.state.app : {});
  const [userInfo] = useState(storageUtil.getUserInfo());

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [dataFilterValue, setDataFilterValue] = useState(null);

  const columns = [
    {
      title: 'App name',
      dataIndex: 'app_name',
      key: 'app_name',
      width: 180,
    },
    {
      title: 'App owner name',
      dataIndex: 'app_owner_name',
      key: 'app_owner_name',
      width: 160,
    },
    {
      title: 'Total duration(s)',
      dataIndex: 'duration_total',
      key: 'duration_total',
      width: 140,
    },
    {
      title: 'Total cost',
      dataIndex: 'amount_total',
      key: 'amount_total',
      width: 150,
      render: (_, record: any) => <>
        <div style={{ fontSize: 18 }}>
          Total: <span style={{ color: 'red', fontWeight: 'bold' }}>${record.amount_total || 0}</span>
        </div>
        <div style={{ color: '#666' }}>Fee: ${record.fee}</div>
        <div style={{ color: '#666' }}>Unit price: ${record.unit_price}/s</div>
      </>
    },
    {
      title: 'Billing cycle',
      dataIndex: 'billing-cycle',
      key: 'billing-cycle',
      width: 160,
      render: (_, record: any) => (
        <div>
          <div style={{ color: 'blue' }}>
            {dayjs(record.bill_started_at).format('YYYY-MM-DD HH:mm:ss')}
          </div>
          <div style={{ color: 'red' }}>
            {dayjs(record.bill_ended_at).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      )
    },
    {
      title: 'Bill status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (_, record: any) => <>
        <Badge status={record.status ? 'success' : 'error'} />
        {record.status ? 'Paid' : 'Unpaid'}
      </>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 130,
      render: (text, record: any) => <Space size="middle">
        <a href={record.invoice_pdf_url} target={'_blank'} rel={'noreferrer'}>Download PDF</a>
        {(_.includes(userInfo.roles, 'client') && record.status === false) &&
          <Button type={'primary'} size={'small'} href={record.invoice_pay_url} target={'_blank'}>Pay</Button>
        }
      </Space>
    },
  ];

  const queryBillListData = ({ app_id, page, pageSize }) => {
    getBillList({ app_id, page, limit: pageSize })
      .then((res) => {
        if (res['data']['data'] != null) {
          setData(res['data']['data']);
          setTotal(res["data"]["meta"]["pagination"]["total"]);
          setAutoCompleteOptions(_.uniqBy(res['data']['data'].map((item: any) => ({
            value: item.app_name,
          })), 'value'));
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    queryBillListData({ app_id: params.id, page, pageSize });
  });

  return (
    <Layout style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
      <TopHeader title={'Bills'} back={currentUserInfo.id || currentApp.id} />
      <Card className={style.container}>
        <TableInfo user={currentUserInfo} app={currentApp} />
        <Space size={'middle'} className="searchview">
          Search app name:
          <AutoComplete
            allowClear
            style={{ width: 200 }}
            placeholder={'Please enter input'}
            options={autoCompleteOptions}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(value) => setDataFilterValue(value)}
            onClear={() => setDataFilterValue(null)}
          />
        </Space>
        <Table
          loading={loading}
          columns={columns}
          rowKey={record => record.id}
          dataSource={localTableDataFilter(data, dataFilterValue, { dataKey: 'app_name' })}
          scroll={{ y: 500 }}
          pagination={{
            pageSize,
            total,
            position: 'bottomRight',
            onChange: (page) => setPage(page),
            showTotal: (total, range) => {
              const label = range[0] === range[1] ? range[0] : `${range[0]}-${range[1]}`;
              return `${label} of ${total} items`;
            },
          }}
        />
      </Card>
    </Layout>
  );
}
