import React from 'react';
import { PageHeader, Button } from 'antd';
import { useNavigate, useRoutes } from 'react-router-dom';
import './index.less';

const TopHeader = (props) => {
  const { title, rightActions, back } = props;
  const navigate = useNavigate();

  return (
    <div>
      <PageHeader
        className="site-page-header-ghost-wrapper"
        title={title}
        onBack={back ? () => navigate(-1) : false }
        extra={rightActions}
      />
    </div>
  );
};

export default TopHeader;